import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Spinner, Center } from "@chakra-ui/react"; // 로딩 중 표시할 스피너

// Lazy loading 적용
const Landing = lazy(() => import("./Pages/Landing"));
const Survey = lazy(() => import("./Pages/Survey"));
const Admin = lazy(() => import("./Pages/Admin"));
const Find = lazy(() => import("./Pages/Find"));

function App() {
  return (
    <BrowserRouter>
      {/* Suspense를 사용해 로딩 중 상태를 처리 */}
      <Suspense
        fallback={
          <Center height="100vh">
            <Spinner size="xl" /> {/* 로딩 중 스피너 표시 */}
          </Center>
        }
      >
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/find/*" element={<Find />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
